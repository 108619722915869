import StocksService from './stocks.service'
import SalesService from './sales.service'
import OrdersService from './orders.service'
import ArticlesService from './articles.service'
import AnalysisService from './analysis.service'
import SuppliersService from './suppliers.service'

const BASE_URL = window.location.protocol + '//' + window.location.hostname
const API_URL = BASE_URL + '/api/v1/'

class ApiService {
  withCache = (apiCall) => {
    return (...args) => {
      const cacheKey = JSON.stringify(args)

      if (this.cache.has(cacheKey)) {
        const value = JSON.parse(this.cache.get(cacheKey))

        return Promise.resolve(value)
      } else {
        return apiCall(...args).then((response) => {
          this.cache.set(cacheKey, JSON.stringify(response))

          return response
        })
      }
    }
  }

  get = (url) => {
    return this.axios.get(url)
  }

  post = (url, payload) => {
    return this.axios.post(url, payload)
  }

  constructor(axiosInstance, pharmacy) {
    this.cache = new Map()
    this.axios = axiosInstance

    this.pharmacy = pharmacy

    this.articles = new ArticlesService(this)
    this.stocks = new StocksService(this)
    this.sales = new SalesService(this)
    this.orders = new OrdersService(this)
    this.analysis = new AnalysisService(this)
    this.suppliers = new SuppliersService(this)
  }
}

export { BASE_URL, API_URL, ApiService }
