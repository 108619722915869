class AnalysisService {
  constructor(apiService) {
    this.api = apiService
  }

  async getLastAnalysis() {
    const pharmacy = this.api.pharmacy
    const executeWithCache = this.api.withCache(() => this.api.get(pharmacy + '/analysis/last'))

    return executeWithCache(pharmacy, 'getLastAnalysis').then((result) => {
      const response = result.data.filter((i) => i.curStock !== -9999)

      return Promise.resolve(response)
    })
  }

  async getAnalysis(date) {
    const pharmacy = this.api.pharmacy
    const executeWithCache = this.api.withCache(() =>
      this.api.get(pharmacy + '/analysis/by_date?date=' + date),
    )

    return executeWithCache(pharmacy, 'getAnalysis', date).then((result) => {
      const response = result.data.filter((i) => i.curStock !== -9999)

      return Promise.resolve(response)
    })
  }
}

export default AnalysisService
