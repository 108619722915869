class SuppliersService {
  constructor(apiService) {
    this.api = apiService
  }

  async getRanking() {
    return await this.api.get(this.api.pharmacy + '/suppliers/ranking')
  }

  async getArticles(supplier) {
    return await this.api.get(this.api.pharmacy + '/suppliers/' + supplier + '/articles')
  }
}

export default SuppliersService
