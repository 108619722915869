export const base_endpoint = (pharmacy, sku) => {
  let endpoint = pharmacy + '/articles'
  if (sku !== undefined && sku !== null) {
    endpoint += '/' + sku
  }
  return endpoint
}

class ArticlesService {
  constructor(apiService) {
    this.api = apiService
  }

  async getArticles(useCache = true) {
    if (!useCache) {
      return await this.api.get(base_endpoint(this.api.pharmacy))
    }

    const executeWithCache = this.api.withCache(() =>
      this.api.get(base_endpoint(this.api.pharmacy)),
    )
    return executeWithCache(this.api.pharmacy, 'getArticles')
  }

  async searchArticles(query) {
    return await this.api.get('/articles/search?query=' + query)
  }

  async getArticle(sku) {
    return await this.api.get(base_endpoint(this.api.pharmacy, sku))
  }

  async getCNPEM(cnpem) {
    return await this.api.get('cnpems/' + cnpem)
  }

  async getCNPEMs() {
    const executeWithCache = this.api.withCache(() => this.api.get('cnpems'))
    return executeWithCache(null, 'getCNPEMs')
  }

  async getMonthlyForecast() {
    const executeWithCache = this.api.withCache(() =>
      this.api.get(this.api.pharmacy + '/articles/forecast'),
    )
    return executeWithCache(null, 'getMonthlyForecast')
  }

  async getCNPEMForecast(cnpem) {
    return await this.api.get(this.api.pharmacy + '/cnpems/' + cnpem + '/forecast')
  }

  async getStockouts() {
    return await this.api.get(this.api.pharmacy + '/articles/stockouts')
  }

  async activate(sku) {
    return await this.set_property(sku, { active: true })
  }

  async deactivate(sku) {
    return await this.set_property(sku, { active: false })
  }

  async set_directPurchase(sku) {
    return await this.set_property(sku, { direct_purchase: true })
  }

  async unset_directPurchase(sku) {
    return await this.set_property(sku, { direct_purchase: false })
  }

  async set_property(sku, property) {
    return await this.api.post(base_endpoint(this.api.pharmacy, sku), property)
  }

  async addToPortfolio(sku, group) {
    const body = { sku: sku, group: group }
    return await this.api.post(base_endpoint(this.api.pharmacy), body)
  }
}

export default ArticlesService
