import { base_endpoint } from './articles.service'

class StocksService {
  constructor(apiService) {
    this.api = apiService
  }

  async getArticleStockHistory(sku) {
    return await this.api.get(base_endpoint(this.api.pharmacy, sku) + '/stock_history')
  }

  async getCNPEMStockHistory(cnpem) {
    return await this.api.get(this.api.pharmacy + '/cnpems/' + cnpem + '/stock_history')
  }
}

export default StocksService
