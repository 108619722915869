import { base_endpoint } from './articles.service'

class SalesService {
  constructor(apiService) {
    this.api = apiService
  }

  async getSalesByArticle(sku, periodicity) {
    return await this.api.get(base_endpoint(this.api.pharmacy, sku) + '/sales/' + periodicity)
  }

  async getSalesByCNPEM(cnpem, periodicity) {
    return await this.api.get(this.api.pharmacy + '/cnpems/' + cnpem + '/sales/' + periodicity)
  }

  async getSalesByCNPEMandSKU(cnpem) {
    return await this.api.get(this.api.pharmacy + '/cnpems/' + cnpem + '/sales_by_sku')
  }
}

export default SalesService
