/* eslint-disable react/prop-types */
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'
import axios from 'axios'
import { API_URL, ApiService } from './services/api.service'
const AuthContext = createContext('auth')

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null)
  const [showLoginToast, setShowLoginToast] = useState(false)
  const navigate = useNavigate()

  const axiosInstance = axios.create({ baseURL: API_URL })

  const apiService = useMemo(
    () => (user ? new ApiService(axiosInstance, user.pharmacy) : null),
    [axiosInstance, user],
  )

  const login = (data) => {
    setUser(data)
    setShowLoginToast(true)
    navigate('/dashboard', { replace: true })
  }

  const logout = (expired) => {
    setUser(null)
    if (expired) {
      window.location.replace('/login?session_expired=1')
    } else {
      window.location.replace('/login')
    }
  }

  const apiSessionIsOn = () => {
    if (!user) {
      return false
    }

    try {
      const token = user.access_token
      const payload = JSON.parse(atob(token.split('.')[1]))

      // check if the token has expired
      return payload.exp > Date.now() / 1000
    } catch (e) {
      return false
    }
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      apiSessionIsOn,
      showLoginToast,
      setShowLoginToast,
      apiService,
      axiosInstance,
    }),
    [user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
