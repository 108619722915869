import { base_endpoint } from './articles.service'

class OrdersService {
  constructor(apiService) {
    this.api = apiService
  }

  // aggregate total qty to be used in list of invoices view
  calculate_order_total = (orders) => {
    orders.forEach((item) => {
      let total_qty = 0

      item.skus.forEach((sku) => {
        total_qty += sku.qty
      })
      item.total_qty = total_qty
    })
    return orders
  }

  async getOrders() {
    return await this.api.get(this.api.pharmacy + '/orders/last')
  }

  async getPendingOrders() {
    const pharmacy = this.api.pharmacy
    const executeWithCache = this.api.withCache(() => {
      return this.api.get(pharmacy + '/orders/pending').then((result) => {
        if (Object.keys(result.data).length === 0) {
          return Promise.resolve({ data: [] })
        }

        let orders = result.data
        orders = this.calculate_order_total(orders)
        // sort descending
        orders.sort((a, b) => new Date(b.arrival_date) - new Date(a.arrival_date))

        return Promise.resolve({ data: orders })
      })
    })
    return executeWithCache(pharmacy, 'getPendingOrders')
  }

  async getNextDelivery(sku) {
    return await this.api
      .get(base_endpoint(this.api.pharmacy, sku) + '/arrivals')
      .then((result) => {
        if (Object.keys(result.data).length === 0) {
          return Promise.resolve({ data: [] })
        }

        let orders = result.data
        orders = this.calculate_order_total(orders)

        return Promise.resolve({ data: orders })
      })
  }
}

export default OrdersService
