import { useEffect, useState } from 'react'
import { useAuth } from './AuthProvider'

const AxiosInterceptor = ({ children }) => {
  const { user, logout, axiosInstance } = useAuth()
  const [interceptorsSet, setInterceptorsSet] = useState(false)

  useEffect(() => {
    const reqUseInterceptor = (config) => {
      if (!user) {
        return config
      }

      const token = user.access_token
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      } else {
        delete config.headers['Authorization']
      }
      return config
    }

    const reqUseErrInterceptor = (error) => {
      return Promise.reject(error)
    }

    const intercept1 = axiosInstance.interceptors.request.use(
      reqUseInterceptor,
      reqUseErrInterceptor,
    )

    const resUseInterceptor = (response) => response
    const resUseErrInterceptor = (error) => {
      if (error.response && error.response.status === 401) {
        logout()
      }
      return Promise.reject(error)
    }

    const intercept2 = axiosInstance.interceptors.response.use(
      resUseInterceptor,
      resUseErrInterceptor,
    )

    setInterceptorsSet(true)

    return () => {
      axiosInstance.interceptors.request.eject(intercept1)
      axiosInstance.interceptors.response.eject(intercept2)
    }
  }, [user])

  return interceptorsSet && children
}

export default AxiosInterceptor
